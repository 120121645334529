<template>
  <v-app>
    

    <section>
      <div class="color"></div>
      <div class="color"></div>
      <div class="color"></div>
      <div class="color"></div>
    </section>
    <v-spacer></v-spacer>
    <v-card
      color="white"
      min-width="50vw"
      max-width="80vw"
      class="mt-10 mx-auto elevation-0"
      min-height="50vh"
      style="background:none !important"
    >
      <v-card-title
        style="font-family:'Neonderthaw' !important;  letter-spacing: 10px;justify-content:center;z-index:8"
        class="text-h2 white--text underline-text"
        >TechCafe13</v-card-title
      >
      <v-card-text class="white--text cardText" style="">
        We thank you for trying to contact us.We'll try to help you the best we
        can.Please Contact Us using the buttons provided below in case of Bugs
        and any Queries related to the Website or Mobile Apps
      </v-card-text>
    </v-card>
    <v-spacer></v-spacer>
    <v-row align="end">
      <v-col>
        <div class="text-center">
          <ul>
            <li>
              <div class="before1"></div>
              <a href="tel:+919774978068">
                <v-icon class="fa fa-phone" aria-hidden="false" x-large></v-icon
              ></a>
            </li>
            <li class="li2">
              <div class="before2"></div>
              <a href="https://wa.me/919774978068" target="_blank">
                <v-icon
                  class="fa fa-whatsapp"
                  aria-hidden="true"
                  x-large
                ></v-icon
              ></a>
            </li>
            <li class="li3">
              <div class="before3"></div>
              <a href="https://www.instagram.com/techcafe_13/" target="_blank">
                <v-icon class="fab fa-instagram" x-large></v-icon
              ></a>
            </li>
            <li class="li4">
              <div class="before4"></div>
              <a href="mailto:jpachuau13@gmail.com">
                <v-icon class="fas fa-envelope" x-large></v-icon
              ></a>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-card> </v-card>

    <v-spacer></v-spacer>
  </v-app>
</template>
<script
  src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
  async
  defer
></script>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data () {
    return {
      phNo: '',
      appVerifier: '',
      otp: '',
      textCodeVisible: true,
      code: ''
    }
  },
  ready () {

  },
  methods: {
    sendOtp () {
      if (this.phNo.length != 10) {
        alert('Invalid Phone Number Format !')
      } else {
        //
        let countryCode = '+91' //india
        let phoneNumber = countryCode + this.phNo
        //
        let appVerifier = this.appVerifier
        //
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then( (confirmationResult)=> {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult
            //
            alert('SMS sent')
          })
          .catch( (error) =>{
            // Error; SMS not sent
            // ...
            alert('Error ! SMS not sent')
          })
      }
    },
    verifyOtp () {
      if (this.phNo.length != 10 || this.otp.length != 6) {
        alert('Invalid Phone Number/OTP Format !')
      } else {
        //
        let vm = this
        let code = this.otp
        //
        window.confirmationResult
          .confirm(code)
          .then( (result)=> {
            // User signed in successfully.
            var user = result.user
            // ...
            //route to set password !
    
          })
          .catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
          })
      }
    },
    initReCaptcha () {
      setTimeout(() => {
        let vm = this
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: function (response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            'expired-callback': function () {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          }
        )
        //
        this.appVerifier = window.recaptchaVerifier
      }, 1000)
    }
  },
  created () {
    this.initReCaptcha()
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');
.iconBtn {
 color: #2697d4;
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
}
.iconBtn:hover {
  color: #fff;
}
.fabBtn {
  background-color:#fff !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transition: 0.5s;
}
.fa-google::before:hover {
  color: green !important;
}
.fabBtn::before,
after {
  position: relative !important;
  left: 50%;
  display: none;
}

.fabBtn:hover{
background-color:#2697d4 !important
}
.circle {
  stroke: #fff;
  position: absolute;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

.circle:hover {
  cursor: pointer;
  animation: stroke 2.5s ease-out forwards !important;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
#recaptcha-container {
  background-color: none;
  z-index: 5;
}

.cardText {
  font-family: 'Neonderthaw';
  font-size: 2.7rem;
  line-height: 40px !important;
  align-content: center;
}
.teset {
  font-family: 'Neonderthaw';
  font-size: 2rem;
  font: white;
}
.contactCard {
  height: 100%;
  background: red !important;

  backdrop-filter: blur(100px) !important;
}

.before1 {
  cursor: pointer;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  opacity: 0.2;
  transform: skewX(40deg) translateX(-120px);
  transition: 0.8s;
}
.before2 {
  cursor: pointer;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  opacity: 0.2;
  transform: skewX(40deg) translateX(-120px);
  transition: 0.8s;
}
.before3 {
  cursor: pointer;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  opacity: 0.2;
  transform: skewX(40deg) translateX(-120px);
  transition: 0.8s;
}
.before4 {
  cursor: pointer;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  opacity: 0.2;
  transform: skewX(40deg) translateX(-120px);
  transition: 0.8s;
}

ul {
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  z-index: 2;
}
ul li {
  outline: none;
  overflow: hidden;
  backdrop-filter: blur(100px);
  list-style: none;
  display: flex;
  margin: 5px;
  transform: translateY(0px);
  transition: 0.2s ease-out;
}
ul li:hover {
  transform: translateY(-10px);
}

ul li:hover .before1 {
  transform: skewX(20deg) translateX(120px);
}
ul li:hover .before2 {
  transform: skewX(20deg) translateX(120px);
}
ul li:hover .before3 {
  transform: skewX(20deg) translateX(120px);
}
ul li:hover .before4 {
  transform: skewX(20deg) translateX(120px);
}

.v-application a {
  text-decoration: none;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
.v-icon::before {
  color: white;
}

.v-icon {
  margin: 12px;
  padding: 8px;
  color: #000;
}
section {
  background-clip: content-box;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(120px);
  background: #b2fefa; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #0ed2f7,
    #b2fefa
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #0ed2f7,
    #b2fefa
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

section .color {
  position: absolute;
}
section::before {
  content: '';
  width: 100vw;
  height: 30px;
  bottom: 50vh;
  position: absolute;
  background: #0ed2f7;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 10;
}
section .color:nth-child(1) {
  top: 70vh;
  left: 50vw;
  background: #84cad6;
  width: 60vw;
  height: 30vh;
}
section .color:nth-child(2) {
  top: 1px;
  background: #20d7f8;
  width: 100vw;
  height: 50vh;
}

section .color:nth-child(3) {
  top: 70vh;
  background: hsla(330, 92%, 38%, 0.815);
  width: 60vw;
  height: 30vh;
}
section .color:nth-child(4) {
  top: 70vh;
  background: hsla(330, 92%, 38%, 0.815);
  width: 60vw;
  height: 30vh;
}
</style>
